//**************************************************************
// 1. scroll fadein animation
//**************************************************************
$(window).scroll(function () {
  $('.fadeIn, .fadeInUp, .fadeInRight, .fadeInLeft, .fadeInScale').each(function() {
    var elemPos = $(this).offset().top,
      scroll = $(window).scrollTop(),
      windowHeight = $(window).height();

    if (scroll > elemPos - windowHeight + 100) {
      $(this).addClass('scrollin');
    }
  });
});



//**************************************************************
// 2. Scroll ※topを離れたらclass付与
//**************************************************************
function init() {
  // スクロールして何ピクセルでクラスを付与させるか
  var px_change   = 500;

  // スクロールのイベントハンドラを登録　ヘッダーにクラス名 smaller を付与
  window.addEventListener('scroll', function(e){
    // 変化するポイントまでスクロールしたらクラスを追加
    if ( $(window).scrollTop() > px_change ) {
      $('header').addClass('smaller');

    // 変化するポイント以前であればクラスを削除
    } else if ( $('header').hasClass('smaller') ) {
      $('header').removeClass('smaller');
      $('#header-nav').removeAttr('checked').prop('checked', false).change();  // topでpull down用のcheckを外す
    }
  });
}
window.onload = init();


//**************************************************************
// 3. Smooth Scroll （TOPに戻るボタン用）
//**************************************************************
$(function(){
  $('a[href^="#"]').click(function(){
    var speed = 500;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("html, body").animate({scrollTop:position}, speed, 'linear');
    return false;
  });
});


//**********************************************************************
// menu
//**********************************************************************
jQuery(document).ready(function($){
  $('.toggle-btn').on('click',function(){
      $('.toggle-line').toggleClass('active');
      $('.global-nav').fadeToggle();
  });
  });


//**********************************************************************
// 4. ナビゲージョンのCurrent表示
//**********************************************************************
$(function(){

  var pageURL = location.pathname,
  pageURLArr = pageURL.split('/'), //パスを分割して配列化する
  pageURLArrCategory = pageURLArr[1]; //パスから第1階層を取得

  $('.navigation li a').each(function (i, v) {
    var selfhref = $(v).attr('href'),
      hrefArr = selfhref.split('/'), //href属性の値を分割して配列化する
      hrefArrCategory = hrefArr[1]; //href属性の第1階層を取得

    //パスの第1階層とhref属性の第1階層を比較して同じ値であればcurrentを付与する
    if (pageURLArrCategory === hrefArrCategory) {
      $(v).addClass('current');
    }

  });
});


//**********************************************************************
// 5. カタログボックスの可変
//**********************************************************************
$(function() {
  $('.catalog-box').hover(
    function() {  
      $(this).addClass('on-hover');
    },
    function() {
      $(this).removeClass('on-hover');
    }
  )
});


//**********************************************************************
// 6. スクロールしたら メニューを隠す
//**********************************************************************
var startPos = 0,winScrollTop = 0;
$(window).on('scroll',function(){
    winScrollTop = $(this).scrollTop();
    if (winScrollTop >= startPos) {
        if(winScrollTop >= 200){
            $('.site-header').addClass('end-up');
        }
    } else {
        $('.site-header').removeClass('end-up');
    }
    startPos = winScrollTop;
});